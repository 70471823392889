import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import ImageTag from "gatsby-image";
// render local version of menu bar to add applepay link on landing
import MenuBar from "./MenuBar";

const Hero = () => {
  const { image, logo } = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "home-banner" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(name: { eq: "home-phone" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <MenuBar/>
      <StyledBackgroundImage Tag="section" className="" fluid={image.sharp.fluid}>
        <div className="grid grid-cols-8 md:grid-cols-12 z-20 p-2 pt-4 lg:pt-20 text-white lg:mb-0">
          <div className="mt-10 col-span-3 hidden lg:block">
            <ImageTag Tag="div" fluid={logo.sharp.fluid} />
          </div>
          <div className="flex flex-col grid md:grid-rows-4 col-span-8 lg:col-span-6 lg:p-0">
            <h1 className="mt-auto row-start-3 md:text-6xl font-semibold">The future of payment solutions</h1>
            <h5 className="row-start-4 md:text-2xl pt-4">Branded &nbsp;|&nbsp; Secure &nbsp;|&nbsp; Customer Driven</h5>
          </div>

          <div className="buttons col-span-6 md:col-span-4 lg:col-span-3 col-start-2 pt-16">
            {process.env.GATSBY_WEBSTORE_URL &&
              <div className="bg-white bg-opacity-20 rounded-2xl md:rounded-3xl mb-2 p-1 md:p-3.5">
                <a href={process.env.GATSBY_WEBSTORE_URL} target="_blank" rel="noreferrer" className="flex flex-wrap flex-col rounded-xl justify-center h-7 md:h-14 px-5" style={{backgroundColor: 'rgba(12,35,64,.94)'}}>
                  Purchase a Gift Card
                  <small className="hidden md:block">Physical or digital </small>
                </a>
              </div>
            }

            <div className="bg-white bg-opacity-20 rounded-2xl md:rounded-3xl p-1 md:p-3.5 mb-3">
              <Link to="/solutions/shopping-centres" className="flex flex-wrap flex-col rounded-xl justify-center bg-white mb-4 h-7 md:h-14 px-5" style={{color: '#6c7179'}}>
                Shopping Centres
                <small className="hidden lg:block">Gifting solutions</small>
              </Link>
              <Link to="/solutions/corporate" className="flex flex-wrap flex-col rounded-xl justify-center bg-white mb-4 h-7 md:h-14 px-5" style={{color: '#6c7179'}}>
                Corporate
                <small className="hidden lg:block">Gifting, rewards, cashbacks</small>
              </Link>
              <Link to="/solutions/government" className="flex flex-wrap flex-col rounded-xl justify-center bg-white h-7 md:h-14" style={{color: '#6c7179', padding: '0 10px 0 20px'}}>
                Government and Councils
                <small className="hidden lg:block">Solutions and case studies</small>
              </Link>
            </div>
          </div>
        </div>
      </StyledBackgroundImage>
    </React.Fragment>
  )
}

// Darken background image so doesn't clash with white text/content
const StyledBackgroundImage = styled(BackgroundImage)`
   &::before,
   &::after {
    filter: brightness(0.8);
  }
`;

export default Hero;
