import React from "react";
import { DarkFooter as Footer } from "@components/footers";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Hero from "./Hero";
import LogoCarousel from "./logo-carousel";
import Cta from "@components/Cta";
import Links from "./links";

const LandingPage = () => (
  <>
    <SEO/>
    <Hero/>
    <Layout>
      <LogoCarousel/>
      <Links/>
      <Cta>
        <span>
          WAIVPAY&rsquo;s digital, physical and cashback payments technology
          for instant rewarding, recognition and incentives.
        </span>
      </Cta>
      <Footer/>
    </Layout>
  </>
);

export default LandingPage;
