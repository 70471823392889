import React from "react";

const Cta = ({ description, children }) => (
  <section className="bg-white">
    <div className="max-w-prose lg:max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="text-center">
        <p className="text-highlight mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl">
          { description ? description : "Get Started Now" }
        </p>
        <p className="max-w-2xl mt-5 mx-auto text-xl text-gray-500">
          {children}
        </p>
      </div>
      <div className="pt-12 flex flex-col sm:flex-row items-center justify-center text-2xl text-gray-500">
        <div className="flex items-center px-6">
          <PhoneIcon/>
          <a href="tel:1300764721" className="text-2xl">
            1300&nbsp;764&nbsp;721
          </a>
        </div>
        <div className="flex items-center px-6">
          <MailIcon/>
          <a href="mailto:sales@waivpay.com" className="text-2xl">
            sales@waivpay.com
          </a>
        </div>
      </div>
    </div>
  </section>
);

const PhoneIcon = () => (
  <Icon>
    <svg className="h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
    </svg>
  </Icon>
);

const MailIcon = () => (
  <Icon>
    <svg className="h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
  </Icon>
);

const Icon = ({ children }) => (
  <span className="inline-flex items-center justify-center h-16 w-16 rounded-md">
    {children}
  </span>
);

export default Cta;

