import React from 'react';
import { Link } from "gatsby";

const Links = () => {
  return (
    <div className="hidden md:block bg-gray">
      <div className="max-w-7xl py-4 sm:py-8 mx-auto px-4 sm:px-6 lg:px-8 mb-4">
        <div className="space-y-10 lg:cols-3 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 align-column">

          <div className="bg-green-900 bg-opacity-40 rounded-xl md:rounded-xl md:p-3.5">
            <Link to="/solutions/shopping-centres" className="font-bold flex flex-wrap flex-col rounded-xl justify-center bg-white mb-4 md:h-14 px-5" style={{color: '#6c7179', margin: '0px'}}>
              <h1>Shopping Centres</h1>
              <small className="hidden lg:inline-block font-normal">Gifting solutions</small>
              <h3 className="hidden lg:inline-block font-extrabold text-right align-text-middle">&gt;</h3>
            </Link>
          </div>

          <div className="bg-green-900 bg-opacity-40 rounded-xl md:rounded-xl md:p-3.5">
            <Link to="/solutions/corporate" className="font-bold flex flex-wrap flex-col rounded-xl justify-center bg-white mb-4 md:h-14 px-5" style={{color: '#6c7179', margin: '0px'}}>
              <h1>Corporate</h1>
              <small className="hidden lg:inline-block font-normal">Gifting, rewards, cashbacks</small>
              <h3 className="hidden lg:inline-block font-extrabold text-right align-text-middle">&gt;</h3>
            </Link>
          </div>

          <div className="bg-green-900 bg-opacity-40 rounded-xl md:rounded-xl md:p-3.5">
            <Link to="/solutions/government" className="font-bold flex flex-wrap flex-col rounded-xl justify-center bg-white md:h-14" style={{color: '#6c7179', padding: '0 10px 0 20px'}}>
              <h1>Government and Councils</h1>
              <small className="hidden lg:inline-block font-normal">Solutions and case studies</small>
              <h3 className="hidden lg:inline-block font-extrabold text-right align-text-middle">&gt;</h3>
            </Link>
          </div>
        </div>

        <div className="pt-10 lg:cols-7 md:space-y-0 md:grid md:grid-cols-7 md:gap-x-8 align-column">
          <div className="space-y-10 lg:cols-1 md:grid md:grid-cols-1 md:gap-x-8 align-column col-start-3 col-span-3">
            {process.env.GATSBY_WEBSTORE_URL &&
            <div className="bg-gray-600 bg-opacity-60 rounded-xl md:rounded-xl md:p-3.5">
              <a href={process.env.GATSBY_WEBSTORE_URL} target="_blank" rel="noreferrer" className="px-5 align-text-middle text-xl font-extrabold flex flex-wrap flex-col rounded-xl justify-center bg-white h-7 md:h-14 text-gray-600">
                Purchase a Gift Card
                <h3 className="align-text-middle text-xl font-extrabold flex flex-wrap flex-col rounded-xl justify-center bg-white h-7 md:h-14 text-right">&gt;</h3>
              </a>
            </div>
            }
          </div>
        </div>

      </div>
    </div>
  );
}

export default Links;
